import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {StartComponent} from './pages/start/start.component';
import {AusbildungComponent} from './pages/ausbildung/ausbildung.component';
import {DatenschutzComponent} from './pages/datenschutz/datenschutz.component';
import {StellenangeboteComponent} from './pages/stellenangebote/stellenangebote.component';
import {StellenangebotComponent} from './pages/stellenangebot/stellenangebot.component';
import {BewerbenComponent} from './pages/bewerben/bewerben.component';
import {BewerbenOkComponent} from './pages/bewerben-ok/bewerben-ok.component';
import {StudiumComponent} from './pages/studium/studium.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';


const routes: Routes = [
    {path: '', component: StartComponent},
    {path: 'datenschutz', component: DatenschutzComponent},
    {path: 'stellenangebote', component: StellenangeboteComponent},
    {
        path: 'stellenangebote/:stellenangebotId/info', children: [
            {path: '**', component: StellenangebotComponent},
        ]
    },
    {
        path: 'stellenangebote/:stellenangebotId/bewerben', children: [
            {path: '**', component: BewerbenComponent}
        ]
    },
    {
        path: 'stellenangebote/:stellenangebotId/bewerben-ok/:uuid', children: [
            {path: '**', component: BewerbenOkComponent}
        ]
    },
    // Landing Pages
    {path: 'studium', component: StudiumComponent},
    {path: 'ausbildung', component: AusbildungComponent},
    {path: '**', redirectTo: '404'},
    {path: '404', component: NotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        scrollPositionRestoration: 'enabled',
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

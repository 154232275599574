<header class="site-header | container-fluid">
    <div class="row">
        <nav
            class="navigation | navbar navbar-expand-md navbar-dark | col-12 | py-0 u-bg-primary"
        >
            <a class="navbar-brand" [routerLink]="['/']">
                <img
                    class="site-header-logo"
                    alt="Glöckle Logo"
                    src="../assets/images/logo-gloeckle-bg-blau.svg"
                />
            </a>
            <button
                (click)="isMenuCollapsed = !isMenuCollapsed"
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div
                [ngbCollapse]="isMenuCollapsed"
                class="collapse navbar-collapse"
                id="navbarSupportedContent"
            >
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item" *ngFor="let item of navigationItems">
                        <a
                            [routerLink]="item.link"
                            (click)="isMenuCollapsed = true"
                            class="nav-link"
                            rel="chapter"
                        >
                            {{ item.name }}
                        </a>
                    </li>
                </ul>

                <ul class="navigation-gptw d-none d-md-block | navbar-nav">
                    <li class="nav-item">
                        <img src="../assets/images/logo_gptw_certified.png" />
                    </li>
                </ul>

                <ul class="navigation-social-media | navbar-nav">
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="https://de-de.facebook.com/GloeckleBau/"
                            rel="noopener"
                            target="_blank"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="faFacebookF"
                            ></fa-icon>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            href="https://www.instagram.com/gloecklebau/"
                            rel="noopener"
                            target="_blank"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="faInstagram"
                            ></fa-icon>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>

<router-outlet></router-outlet>

<footer class="c-footer | container-fluid">
    <div class="row justify-content-center">
        <div class="col-12 col-sm-6 col-md-4">
            <h4 class="mb-2">Kontakt</h4>
            <div>
                <p>Unternehmensgruppe Glöckle</p>
                <p>
                    Wirsingstraße 15<br />
                    97424 Schweinfurt
                </p>
            </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 mt-4 mt-sm-0">
            <h4 class="mb-2">Weitere Informationen</h4>
            <nav class="c-footer__navigation">
                <ul>
                    <li>
                        <a
                            href="https://www.gloeckle-bau.de/impressum/"
                            aria-current="page"
                            ><i class="fa fa-info-circle"></i>
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="faInfoCircle"
                            ></fa-icon>
                            Impressum
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/datenschutz/']" rel="chapter">
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="faCheckCircle"
                            ></fa-icon>
                            Datenschutz
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.gloeckle-bau.de/datenschutz-social-media/"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="faCheckCircle"
                            ></fa-icon>
                            Datenschutz Social Media
                        </a>
                    </li>
                    <li class="d-sm-none">
                        <a
                            href="https://de-de.facebook.com/GloeckleBau/"
                            rel="noopener"
                            target="_blank"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="faFacebookF"
                            ></fa-icon>
                            Facebook
                        </a>
                    </li>
                    <li class="d-sm-none">
                        <a
                            href="https://www.instagram.com/gloecklebau/"
                            rel="noopener"
                            target="_blank"
                        >
                            <fa-icon
                                [fixedWidth]="true"
                                [icon]="faInstagram"
                            ></fa-icon>
                            Instagram
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</footer>

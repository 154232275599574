<main class="container-fluid">
    <div class="t-not-found | row justify-content-center">
        <div class="col-md-10">
            <h1 class="t-not-found__title">Seite nicht gefunden</h1>
            <img
                class="t-not-found__image | img-fluid block"
                src="/assets/illustrations/QA_engineers.svg"
                alt="Illustration einer Person mit Helm"
            />
            <p>
                <a
                    class="btn btn-primary | t-not-found__button"
                    [routerLink]="['/']"
                    >Zur Startseite</a
                >
            </p>
        </div>
    </div>
</main>

import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';

import {environment} from 'src/environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {CopytoclipboardComponent} from './components/copytoclipboard/copytoclipboard.component';

import {AusbildungComponent} from './pages/ausbildung/ausbildung.component';
import {BewerbenOkComponent} from './pages/bewerben-ok/bewerben-ok.component';
import {BewerbenComponent} from './pages/bewerben/bewerben.component';
import {DatenschutzComponent} from './pages/datenschutz/datenschutz.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {StartComponent} from './pages/start/start.component';
import {StellenangebotComponent} from './pages/stellenangebot/stellenangebot.component';
import {StellenangeboteComponent} from './pages/stellenangebote/stellenangebote.component';
import {StudiumComponent} from './pages/studium/studium.component';

registerLocaleData(localeDe, 'de');

@NgModule({
    declarations: [
        AppComponent,
        CopytoclipboardComponent,
        AusbildungComponent,
        BewerbenComponent,
        BewerbenOkComponent,
        DatenschutzComponent,
        StartComponent,
        StellenangebotComponent,
        StellenangeboteComponent,
        StudiumComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatInputModule,
        MatMomentDateModule,
        MatSelectModule,
        MatToolbarModule,
        MatTooltipModule,
        FontAwesomeModule,
        NgbModule,
        ReactiveFormsModule,
        RecaptchaV3Module
    ],
    providers: [
        {provide: APP_BASE_HREF, useValue: '/'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        {provide: LOCALE_ID, useValue: 'de'},
        {
            provide: MAT_DATE_FORMATS, useValue: {
                parse: {
                    dateInput: 'DD.MM.YYYY'
                },
                display: {
                    dateInput: 'DD.MM.YYYY',
                    monthYearLabel: 'YYYY'
                }
            }
        },
        {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.googleRecaptchaSiteKey}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

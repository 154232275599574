import {Component, OnInit} from '@angular/core';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle, faEnvelope, faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bewerberportal';
  faCheckCircle = faCheckCircle;
  faFacebookF = faFacebookF;
  faInfoCircle = faInfoCircle;
  faInstagram = faInstagram;
  faEnvelope = faEnvelope;
  faPhone = faPhone;

  public isMenuCollapsed = true;

  public navigationItems = [
      { name: 'Start', link: '/'},
      { name: 'Stellenangebote', link: '/stellenangebote/'},
      { name: 'Ausbildung', link: '/ausbildung/'},
      { name: 'Studium', link: '/studium/'},
  ];
}
